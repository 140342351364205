<template>
  <div>
    <splide :options= "options" :slides='slides' >
      <splide-slide v-for="slide  in slides.deliveries" :key="slide.solar_date">
        <v-card class="pa-1 mt-2">
        <v-row class="text-center d-flex flex-column pa-2 mt-1">
          <span class="primaryText--text medium-font" v-text="slide.Day_Title"></span>
          <small class="primaryText--text medium-font" v-text="slide.solar_date"></small>
        </v-row>
      <v-divider class="my-2"></v-divider>
      <div class="overfow-y">
        <div>
          <v-radio-group v-model="radioGroup">
            <v-row v-for="shift in slide.shifts" :key="shift.Delivery_Shift_ID_PK" class="d-flex align-center mb-1">
              <v-col cols="8">
                <v-radio v-if="shift.had_capacity == 1" :label="shift.Shift_Title" :value="slide.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
                <v-radio v-else disabled :label="shift.Shift_Title" :value="slide.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
              </v-col>
              <v-col class="text-start">
                <span v-if="shift.had_capacity == 0" class="primaryText--text caption">تکمیل</span>
              </v-col>
            </v-row>             
          </v-radio-group>
        </div>
      </div>
    </v-card>
    </splide-slide>
    </splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { mapState } from 'vuex'
import store from '@/store'
export default {
   components: {
    Splide,
    SplideSlide,
  },
  computed: {
    ...mapState({
      cargos: state => state.basket.delivery_Time,
      slides: state => state.basket.delivery_Time.deliveries,
      error_time: state => state.basket.error_time
    })
  },
  data:() => ({
    options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 1.3,
      arrows: false,
      drag: true,
      pagination: false,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl',
    },
    radioGroup: ''
  }),
  watch: {
    radioGroup: {
      handler: function () {
        const date = this.radioGroup.split(',')
        const Time = {
          date: date[0],
          shift: date[1],
          cargoId: this.cargos.cargos_id
        }
        store.dispatch('set_time_basket', Time)
      }
    },
    // cargos: {
    //   handler: function () {
    //     this.slides = this.cargos.deliveries
    //   }
    // }
  }
}
</script>